@for (notification of notificationsToDisplay; track notification) {
    <div class="system-notification" [ngClass]="{ info: (notification.type || INFO) === INFO, warning: notification.type === WARNING }">
        <div class="notification-text">
            <span class="notification-title">
                @if ((notification.type || INFO) === INFO) {
                    <fa-icon [icon]="faInfoCircle" class="left-slide-in" />
                }
                @if (notification.type === WARNING) {
                    <fa-icon [icon]="faExclamationTriangle" class="left-slide-in" />
                }
                <span class="left-slide-in"
                    >{{ notification.title }}
                    @if (notification.text) {
                        <span>:</span>
                    }
                </span>
            </span>
            <span class="notification-content left-slide-in">{{ notification.text }}</span>
        </div>
        <fa-icon class="notification-close" [icon]="faTimes" (click)="close(notification)" />
    </div>
}
