<div>
    <div class="row">
        <div class="col">
            <h1 jhiTranslate="error.title"></h1>

            <div [hidden]="!errorMessage">
                <div class="alert alert-danger">{{ errorMessage }}</div>
            </div>
            <div [hidden]="!error403" class="alert alert-danger" jhiTranslate="error.http.403"></div>
            <div [hidden]="!error404" class="alert alert-danger" jhiTranslate="error.http.404"></div>
        </div>
    </div>
</div>
